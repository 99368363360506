import './Footer.css'

import { NavLink } from "react-router-dom";

 // Fonction pour faire défiler la page vers le haut
 const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

export default function Footer() {
    return(
        <>
        <div className='allfooter'>
            <div className='footer-bg'>
                    <div className='footer'>
                            <div className='footer-contact'>
                                <h2 className='footer-h2'>ME CONTACTER</h2>
                                <p>AR MULTI SERVICES</p>
                                <p> 57160 Moulins-Lès-Metz</p>
                                <p>contact@armultiservices.fr</p>
                                <p>06 51 23 85 28</p>
                            </div>
                            
                            <div className='footer-links'>
                                <h2 className='footer-h2'>MENU</h2>
                                <NavLink to="/" onClick={scrollToTop()} className="footer-link">Accueil</NavLink>
                                <NavLink to="/Services" onClick={scrollToTop()} className="footer-link">Services</NavLink>
                                <NavLink to="/DemandeDeDevis" onClick={scrollToTop()} className="footer-link">Demande de devis</NavLink>
                                <NavLink to="/Contact" onClick={scrollToTop()} className="footer-link">Contact</NavLink>
                                <NavLink to="/MentionsLégales" onClick={scrollToTop()} className="footer-link footer-link-bottom">Mentions légales</NavLink>
                            </div>

                    </div>

                    </div>
                    <div className='color-bar'>
                        <p>&copy; 2023 AR MULTI SERVICES</p>
                    </div>
            </div>
        </>
    )
}