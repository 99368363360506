import './Card.css'
import * as React from 'react';


export default function Services() {
    return(
        <>
        <div className='services'>
            <div className='services-titre' id='Services'>
                <h1 >Mes services</h1>
                <p>Je suis disponible pour vos petits bricolages et divers services de tous corps de métiers.</p>
            </div>
        </div>

        <br /><br /><br /><br />
        <br />
        <div className='row1-container'>
            <div className='box box-down cyan ' >
                <h2>Electricité</h2>
                <p>
                        
                </p>
                <img width="64" height="64" src="https://img.icons8.com/wired/64/0097b2/plug.png" alt="plug" className='vignets-card'/>
            </div>
            
            <div className='box red'>
                <h2>Plomberie</h2>
                <p>
                        
                </p>
                <img alt='.' className='vignets-card' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACC0lEQVR4nO2ZPWsUURSGjzZ+FLGyEG31t2wRdd43TCEWwdJUgv2ujRaC/0NcLDSFZVALPwoRImhlH8wKGhfmnCJXrruQgLOTOyETz5V54MAWszAvzzn3XBgx4IuRoU0p+Vq8YcDn1kGAV+IRaxlEvGJ9EGdYb8QZ1hvJ3EgQOSEeUWDSaiGurNxIDaPkewXeHtd2/9TWymHnKuHWMInvo+S6AWtT4FJykD9/chLE/r7X7Ro5roriSoqRNa9BbM+UGTlsbOmob57cbRDbq3FYXj672Ao5ziRIMPLpQjOxB+f6cggSYps1WRnmEkQBq8jLtUGiLgMe5xDEZvVkoZU4SF3MSxdBlNydluXFxWGiGXJ4lDPTkZFg5G05iNiDUd9RHM2dBQGeSypRX0xu5DMjN9vezTo2sinHgZLvlHyT+nwFXGs1J8BEPBJm83ko2+6wPogzrDfiDOuNOMNyNxJmO+Rj9t9rwmh00oAP/833mjoq8mosyZkwGJwy4Gus+Ftyxci7+67tdyRHQlmeU3J732B/C4PBkuSGkvdrhvue5EQALig5rTluf4ayPC+5oMCDhmN3JDkQyvJMnIeGJbgVVldPi3cUuJWwCG+Kd5R8kXA1WRfvKPk9Ici2eEeBnYTW+iHeUeBlQpAN8U5VFDwoSFUU1yUHFHjUYOPhv36/VlQAYgsp8GteG3UmfgO/bwDC5G8MpwAAAABJRU5ErkJggg=="/>            </div>

            <div className='box box-down green'>
                <h2>Jardin</h2>
                <p> 
                        
                </p>

                <img width="50" height="50" className='vignets-card' src="https://img.icons8.com/quill/50/40C057/yard-work.png" alt="yard-work"/>

            </div>

            <div className='box orange'>
                    <h2>Petits bricolages</h2>
                    <img width="64" height="64" className='vignets-card' src="https://img.icons8.com/glyph-neue/64/FD7E14/hammer.png" alt="hammer"/>
                </div>
            
        </div>

        <br /><br /><br /><br /><br />
        </>
    )
}