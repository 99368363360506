import React from 'react'
import './Home.css';


import Footer from '../../Components/Footer/Footer'
import Slider from '../../Components/Slider/Slider';
import Aos from 'aos';




export default function Home() {
    return (
      <>
        <div className='main' id='/'>
          <Slider /> <br />
          <div className='home-container-A'>
            <div>
              <div className='titre'>
                <div className='titre-left'>
                  <h1>ANTOINE RODRIGUE</h1>
                  <h3>(AR MULTI SERVICES)</h3>
                  <p>978 342 566 <span className='p-green'>· Active</span></p>
                </div>
                <div className='titre-right'>
                  <p>Adresse : RUE ST JEAN, 57160 MOULINS-LES-METZ</p>
                  <p>Activité : Autres travaux de finition</p>
                  <p>Effectif : 0 salarié <span className="p-gray">(donnée 2023)</span></p>
                  <p>Création : 01/08/2023</p>
                  <p>Dirigeant : <span className="p-soulign">RODRIGUE ANTOINE</span></p>
                </div>
              </div>
              <div className="home-row1"><div className="home-row1-card"><h2>Informations juridiques de ANTOINE RODRIGUE</h2><br /><p>SIREN : 978 342 566 </p><p>SIRET (siège) : 978 342 566 00012 </p><p>Forme juridique : Entrepreneur individuel</p><p>Micro-entreprise : Oui</p><p>Inscription au RCS : Non inscrit</p><p>Inscription au RNE : <span className="p-green">INSCRIT </span></p></div><div className="home-row1-card"><h2>Activité de ANTOINE RODRIGUE</h2><br /><p className="activity"> <span className="p-activity1">Activité principale déclarée :</span> <span className="p-activity2"> Activités diverses telles que, l'entretien du jardin, bricolage, plomberie, électricité, réparation et nettoyage</span></p><p>Code NAF ou APE : 43.39Z  (Autres travaux de finition)</p><p>Domaine d’activité : Travaux de construction spécialisés</p><p>Forme d'exercice : Libérale non réglementée</p></div></div>
              <div className="home-row2"><div className="home-row2-card"><h2>Dirigeants et représentants de ANTOINE RODRIGUE</h2><br /><p className="p-name"><span>RODRIGUE ANTOINE</span> <span>En poste depuis le 01/08/2023</span></p><p>Chef d'entreprise</p></div><div className="home-row2-card-img"><div data-aos="fade-up" className="vignets aos-init"><img src="/static/media/logo.af9a0720207bb5a91c78.png" alt="." className="home-img"/></div></div></div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  }
  

Aos.init()