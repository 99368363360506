import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { NavLink } from "react-router-dom";
import React, { useState } from 'react';
import './Navigation.css'
import logo from './img/logo.png'

function Navigation() {

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  // Fonction pour faire défiler la page vers le haut
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <>
      {['sm'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <img src={logo} alt="logo" className='navbar-brand'/> <h3>AR MULTI SERVICES</h3>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleToggle}/>
            <Navbar.Collapse id="offcanvasNavbar">
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                show={expanded}
                onHide={handleClose}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <NavLink to="/" className="nav-link" onClick={() => { handleClose(); scrollToTop(); }}>Accueil</NavLink>
                    <NavLink to="/Services" className="nav-link" onClick={() => { handleClose(); scrollToTop(); }}>Services</NavLink>
                    <NavLink to="/DemandeDeDevis" className="nav-link" onClick={() => { handleClose(); scrollToTop(); }}>Demande de devis</NavLink>
                    <NavLink to="/Contact" className="nav-link" onClick={() => { handleClose(); scrollToTop(); }}>Contact</NavLink>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;